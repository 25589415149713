<template>
  <div class="design-special-style">
    <div class="label">
      <span class="required-icon" v-if="required">*</span>
      <span>{{ title }}</span>
    </div>
    <div class="upload-box" v-if="isAnnex">
      <PlusOutlined />
    </div>
    <div class="select-boxs" v-if="!isAnnex">
      <div class="tips">{{ tipsText || '请选择在线文件' }}</div>
      <RightOutlined class="select-right" />
    </div>
    <div class="tips" v-if="tipsText && isAnnex">{{ tipsText }}</div>
  </div>
</template>

<script setup>
import { PlusOutlined } from '@ant-design/icons-vue'
import { RightOutlined } from '@ant-design/icons-vue'
defineProps(['title', 'tipsText', 'required', 'isAnnex'])
</script>

<style lang="less" scoped>
.upload-box {
  width: 120px;
  height: 120px;
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  background: #ffffff;
  text-align: center;
  line-height: 120px;
  margin-top: 8px;
  span.anticon {
    font-size: 40px;
    font-weight: normal;
    color: #e0e0e0;
  }
}
.select-boxs {
  width: 306px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #e3e3e3;
  background: #ffffff;
  text-align: center;
  margin-top: 8px;
  border-radius: 2px;
  padding: 5px 12px;
  font-size: 14px;
  .tips {
    padding: 0;
    line-height: 20px;
  }
  .select-right {
    color: rgba(0, 0, 0, 0.25);
    font-size: 14px;
    line-height: 26px;
  }
}
.required-icon{
  color:#c3161c !important;
}
</style>
